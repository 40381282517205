import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from '../api-config';

@Injectable()
export class ApiService {
  protected url = inject(API_URL);
  protected client = inject(HttpClient);

  protected get headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  get<T>(
    path: string,
    params: {
      [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
    } = {}
  ): Observable<T> {
    return this.client.get<T>(`${this.url}${path}`, {
      params: new HttpParams({ fromObject: params }),
      headers: this.headers,
    });
  }

  post<T>(path: string, body: Record<string, unknown> = {}): Observable<T> {
    return this.client.post<T>(`${this.url}${path}`, JSON.stringify(body), {
      headers: this.headers,
    });
  }

  put<T>(path: string, body: Record<string, unknown>): Observable<T> {
    return this.client.put<T>(`${this.url}${path}`, JSON.stringify(body), {
      headers: this.headers,
    });
  }

  delete<T>(path: string): Observable<T> {
    return this.client.delete<T>(`${this.url}${path}`, {
      headers: this.headers,
    });
  }

  upload<T>(path: string, files: File[]): Observable<T> {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`file[${index}]`, file, file.name);
    });

    return this.client.post<T>(`${this.url}${path}`, formData);
  }
}
